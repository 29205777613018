@import "_default.scss_include-mixins";

.page.content--therapieen {

	@include md {
		margin-top:60px;
	}

	/*
	Bulbs
	*/
	.block.therapieen {
		.blur-bulb-1 {
			position:absolute;
			width:800px;
			height:800px;
			left:-200px;
			top:0px;
			opacity:0.3;

			@include md {
				width:200px;
				height:200px;
			}
		}
	}

	.block.quote {
		.blur-bulb-1 {
			position:absolute;
			width:200px;
			height:200px;
			right:-100px;
			top:100px;
			opacity:0.5;

			@include md {
				width:100px;
				height:100px;
			}
		}
	}

}