@import "_default.scss_include-mixins";

body {
	font-family: 'Ubuntu', sans-serif;
	color:#000;
	background-color:#fff;
	overflow-x:hidden;
	width:100vw;
	min-block-size: 100dvb;
}

.page-wrapper {
	@include md {
		float:left;
		width:100vw;
		overflow-x:hidden;
	}
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}

/*
Globals
*/
.blur-bulb {
	float:left;
	border-radius:50%;
	filter: blur(100px);
	z-index:-1;

	@include md {
		opacity:0.7 !important;
		transform:scale(0.5);
	}
	
	&.green {
		background-color:#73CB6C;
	}
	&.blue {
		background-color:#669ACC;
	}
	&.purple {
		background-color:#7F1E9B;
	}

}

/*
Menu button
Menu button
Menu button
Menu button
*/
.menu-button {
	background-color:#F6F6F6;
	border-radius:50px;
	cursor:pointer;
	box-shadow:0 5px 5px rgba(0,0,0,0.2);
	transition:background-color 0.3s ease;

	&.active {
		.line-1 {
			top:5px !important;
			transform:rotate(225deg);
		}
		.line-2 {
			opacity:0;
		}
		.line-3 {
			top:5px !important;
			transform:rotate(-225deg);
		}		
	}

	&:hover {
		background-color:#E5E5E5;
	}

	.inside {
		float:left;
		width:100%;
		padding-top:10px;
		padding-bottom:10px;
		padding-left:30px;
		padding-right:30px;

		.label {
			float:left;
			font-size:18px;
			line-height:18px;
		}

		.icon {
			float:left;
			position:relative;
			margin-left:10px;
			margin-top:3px;
			width:20px;

			.line {
				position:absolute;
				width:100%;
				height:3px;
				background-color:#000;

				&.line-1 {
					top:0;
					transition:all 0.3s ease;
				}
				&.line-2 {
					top:6px;
				}
				&.line-3 {
					top:12px;
					transition:all 0.3s ease;
				}
			}
		}
	}
}

/*
Blocks
Blocks
Blocks
Blocks
*/
.block {
	float:left;
	width:100%;

	/*
	Breadcrumbs
	Breadcrumbs
	Breadcrumbs
	Breadcrumbs
	*/
	&.breadcrumbs {

		margin-top:80px;

		@include md {
			display:none;
		}

		a {
			float:left;
			font-size:20px;
			color:#C5C5C5;
			text-decoration:none;
			transition:all 0.5s ease;

			&:hover {
				color:#A4A4A4;
			}
		}

		.spacer {
			float:left;
			width:20px;
			height:20px;
			margin-left:10px;
			margin-right:10px;

			svg {
				fill:#C5C5C5;
			}
		}

	}

	/*
	Therapieën
	Therapieën
	Therapieën
	Therapieën
	*/
	&.therapieen {

		.container {
			position:relative;

			.wrapper {
				float:left;
				width:100%;
				padding:110px;
				padding-top:80px;
				padding-bottom:80px;
				margin-top:70px;
				margin-bottom:110px;
				background-color:#DAE6B6;
				border-top-left-radius: 40px;
				border-top-right-radius: 180px;
				border-bottom-left-radius: 80px;
				border-bottom-right-radius: 180px;

				@include md {
					padding:20px;
					margin-top:40px;
					margin-bottom:40px;
					border-top-left-radius: 10px;
					border-top-right-radius: 50px;
					border-bottom-left-radius: 40px;
					border-bottom-right-radius: 50px;
				}

				.buttons {
					float:left;
					width:100%;
					margin-top:40px;
					padding-bottom:40px;
				}

			}
			
		}

	}

	/*
	Over de praktijk
	Over de praktijk
	Over de praktijk
	Over de praktijk
	*/
	&.overdepraktijk {

		.container {
			position:relative;

			.blur-bulb-1 {
				position:absolute;
				width:700px;
				height:700px;
				left:-600px;
				top:-400px;
				opacity:0.3;

				@include md {
					width:200px;
					height:200px;
				}
			}

			.wrapper {
				float:left;
				width:100%;

				.textblock-1 {
					@include md {
						margin-top:40px;
					}
				}

				.image-outside {
					position:relative;
					float:left;
					margin-left:-100px;
					width:450px;
					height:450px;
					background-color:#e1e1e1;
					border-radius:50%;
					border:5px solid #fff;
					background-size:contain;
					background-position:center;

					@include md {
						float:left;
						margin-left:10%;
						width:250px;
						height:250px;
					}

					.image-inside {
						position:absolute;
						bottom:-60px;
						right:-40px;
						width:250px;
						height:250px;
						background-color:#888;
						border-radius:50%;
						border:5px solid #fff;
						z-index:1;
						background-size:contain;
						background-position:center;

						@include md {
							bottom:-30px;
							right:-20px;
							width:100px;
							height:100px;
						}
					}
				}

				.buttons {
					float:left;
					width:100%;
					margin-top:40px;
					padding-bottom:40px;
				}

			}
			
		}

	}

	/*
	Quote
	Quote
	Quote
	Quote
	*/
	&.quote {

		.container {
			position:relative;

			.wrapper {
				float:left;
				width:100%;
				padding:110px;
				padding-top:80px;
				padding-bottom:80px;
				margin-top:110px;
				margin-bottom:110px;
				//background-color:rgba(102,154,204,0.15);
				box-shadow:0 0 60px rgba(102,154,204,0.5);
				border:5px solid rgba(102,154,204,0.1);
				border-radius:300px;

				@include md {
					padding:20px;
					margin-top:40px;
					margin-bottom:40px;
				}

				.textblock-1 {

					float:left;

					p {
						float:none;
						display:inline-block;
						font-family: 'EB Garamond', serif;
						width:80%;
						font-size:40px;
						line-height:50px;
						color:#2A7CB2;

						@include md {
							font-size:16px;
							line-height:26px;
						}
					}
				}

			}

		}

	}

	/*
	Menu button
	Menu button
	Menu button
	Menu button
	*/
	&.menubutton {

		.container {
			position:relative;

			.wrapper {
				float:left;
				width:100%;
				text-align:center;
				padding-bottom:80px;

				.menu-button {
					display:inline-block;
				}

			}

		}

	}

}