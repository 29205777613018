@import "_default.scss_include-mixins";	 

header {

	position:fixed;
	top:0;
	width:100%;
	margin-top:40px;
	z-index:10;

	@include md {
		width:100vw;
		margin-top:20px;
	}

	.menu-button {
		float:right;
		margin-right:20px;
		margin-top:37px;
		pointer-events:auto !important;

		@include md {
			margin-right:10px;
			margin-top:11px;
		}
	}

	.logo {
		float:right;
		margin-top:10px;
		width:100px;
		height:100px;
		margin-right:-40px;
		pointer-events:auto !important;

		@include md {
			width:40px;
			height:40px;
			margin-right:10px;
		}

		svg {
			.st0{ fill:#679ACB; stroke:#231F20; stroke-miterlimit:10; }
			.st1{ fill:#FFFFFF; stroke:#010101; stroke-miterlimit:10; }
			.st2{ fill:#FFFFFF; stroke:#231F20; stroke-miterlimit:10; }
		}
		
	}
	
}