@import "_default.scss_include-mixins";

.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	width:100vw;
	height:100vh;
	background-color:#fff;
	z-index:10;
	overflow:hidden;

	.border-left { // moet dit?
		position:absolute;
		left:0;
		width:20px;
		height:100vh;
		background:#669ACC;

		@include md {
			display:none;
		}
	}

	/*
	Bulbs
	*/
	.blur-bulb-1 {
		position:absolute;
		width:400px;
		height:400px;
		left:-100px;
		top:-100px;
		opacity:0.3;

		@include md {
			width:100px;
			height:100px;
		}

	}

	.blur-bulb-2 {
		position:absolute;
		width:800px;
		height:800px;
		right:100px;
		bottom:-100px;
		opacity:0.3;

		@include md {
			width:300px;
			height:300px;
		}
	}

	@include md {
		height:--vh;
	}

	.scroller {
		position:relative;
		width:100vw;
		height:100vh;
		overflow:scroll;

		@include md {
			height:--vh;
		}

	}

	.mobile-menu-inside {
		position:absolute;
		width:100%;
		top:50%;
		left:50%;
		transform:translate(-50%,-40%);

		@include md {
			transform:none;
			left:20px;
			top:20px;
			margin-top:80px;
			padding-bottom:100px;
		}

		h2 {
			@include md {
				margin-top:20px;
			}	
		}

		.name {
			position:absolute;
			top:-80px;
			opacity:0;

			@include md {
				display:none;
			}

			// transition: opacity 2s ease;

			/* &.loaded {
				opacity:1;
			} */

			h3 {
				float:left;
				margin:0;
				padding:0;
				font-size:30px;
				line-height:30px;
				color:#e1e1e1;
			}
		}

		a {
			text-decoration:none;
			&:hover, &.active {
				text-decoration:underline;
			}
		}
	}

}