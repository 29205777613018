@import "_default.scss_include-mixins";

.page.content--home {

	/*
	Bulbs
	*/
	.block.therapieen {
		.blur-bulb-1 {
			position:absolute;
			width:400px;
			height:400px;
			right:-100px;
			top:-100px;
			opacity:0.5;

			@include md {
				width:200px;
				height:200px;
			}
		}
	}

	.block.quote {
		.blur-bulb-1 {
			position:absolute;
			width:300px;
			height:300px;
			right:-200px;
			top:-100px;
			opacity:0.5;

			@include md {
				width:100px;
				height:100px;
			}
		}
	}

	/*
	Hero
	Hero
	Hero
	Hero
	*/
	.hero {

		float:left;
		width:100%;
		margin-top:10vw;

		@include md {
			margin-top:100px;
		}

		.container {
			position:relative;

			.blur-bulb-1 {
				position:absolute;
				width:200px;
				height:200px;
				left:-50px;
				top:-50px;
				opacity:0.5;
			}

		}		

		h2 {
			font-size:50px;
			line-height:50px;

			@include md {
				font-size:25px;
				line-height:25px;
			}
		}

		p {
			font-size:34px;
			line-height:53px;

			@include md {
				font-size:16px;
				line-height:26px;
			}
		}

		.video {
			float:left;
			position:absolute;
			
			top:10px;
			margin-left:-30px;
			width:450px;
			height:450px;

			// margin-left:20px;
			// width:350px;
			// height:350px;
			border-radius:50%;
			overflow:hidden;

			opacity:0;
			transition: opacity 5s ease, top 1s ease;

			mix-blend-mode: darken;

			@include md {
				width:150px;
				height:170px;
			}

			video {
				// transform:scale(0.95);
				width:100%;
				height:100%;
				object-fit: contain;
			}

			&.loaded {
				top:-40px;
				opacity:1;
			}
		}

		.buttons {
			position:relative;
			float:left;
			width:100%;
			margin-top:40px;

			.video {
				position:right;
				bottom:0;
				right:0;
				margin-left:0;
			}
		}
	}

}