@import "_default.scss_include-mixins";

.button-1 {

	display:inline-block;

	padding-top:8px;
	padding-bottom:8px;
	padding-left:25px;
	padding-right:25px;	
	border-radius:50px;

	transition:0.3s transform ease, background-color 0.3s ease, box-shadow 0.5s ease;

	box-shadow:0 5px 5px rgba(0,0,0,0.2);

	margin-right:20px;
	margin-bottom:20px;

	@include md {
		margin-bottom:10px;
		margin-right:0;
		padding-left:20px;
		padding-right:15px;
		padding-top:8px;
		padding-bottom:8px;
	}

	// &.active {
	// 	padding-left:40px;
	// 	background-image:url(../img/static/assets/loading.svg);
	// 	background-position:10px;
	// 	background-repeat:no-repeat;
	// 	background-size:7%;

	// 	.label {
	// 		margin-left:20px;
	// 	}

	// 	@include md {
	// 		padding-left:30px;
	// 	}
	// }

	&.blue {
		background-color:#669ACC;
		
		&:hover {
			background-color:#5B8AB8;
			box-shadow:0 5px 15px rgba(0,0,0,0.6);
		}

	}
	&.white {
		background-color:#fff;

		.label {
			color:#000 !important;
		}
		.icon {
			svg {
				fill:#000 !important;
			}
		}
		
		&:hover {
			background-color:#E5E5E5;
			box-shadow:0 5px 15px rgba(0,0,0,0.6);
		}
	}

	&.bigger {

		margin-right:25px;
		margin-bottom:25px;

		padding-top:15px;
		padding-bottom:15px;
		padding-left:44px;
		padding-right:44px;

		@include md {
			margin-bottom:10px;
			margin-right:0;
			padding-left:20px;
			padding-right:15px;
			padding-top:8px;
			padding-bottom:8px;
		}	

		.label {
			font-size:30px !important;

			@include md {
				font-size:16px !important;
			}
		}
	}

	.inside {

		float:left;
		width:100%;

		.icon {
			float:right;
			margin-top:0px;
			margin-left:10px;

			svg {
				float:left;
				width:24px;
				height:24px;
				fill:#fff;

				@include md {
					width:20px;
					height:20px;
				}
			}
		}

		.label {
			float:left;
			margin-top:1px;
			font-size:20px;
			line-height:20px;
			color:#fff;
			font-weight:normal;

			@include md {
				font-size:16px !important;
			}

		}

		&.no-icon {
			.label {
				margin-left:0;
			}
		}

	}

	&:hover {

		//background-color:#2A4F9C;
		transform:scale(1.02);

		.label {
			// text-decoration:underline;
		}

		.icon {
			// margin-left:30px;
		}
		
	}

}