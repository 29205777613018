@import "_default.scss_include-mixins";

.popup--content {
	float:left;
	
	&.login {
		width:400px;

		@include md {
			width:250px;
		}

		input {
			text-align:center;
		}

	}

	.bt-exit {
		position:absolute;
		right:-30px;
		top:-30px;
		width:40px;
		height:40px;
		cursor:pointer;

		@include md {
			width:30px;
			height:30px;
			right:-20px;
			top:-20px;
		}

		.lines {
			position:absolute;
			top:50%;
			left:50%;
			width:100%;
			height:20px;
			transform:translate(-50%,-50%);

			.line {
				position:absolute;
				width:80%;
				margin-left:10%;
				height:4px;
				background-color:#fff;

				&.line-1 {
					top:8px;
					transform:rotate(-45deg);
				}

				&.line-2 {
					top:8px;
					transform:rotate(45deg);
				}

			}

		}

	}

	.content {
		position:relative;
		float:left;
		width:100%;
		color:#000;
		background-color:#F9BC43;
		padding:40px;
		padding-top:60px;
		border-radius:20px;
		text-align:center;

		@include md {
			padding:10px;
			padding-top:30px;
		}

		.logo {
			position:absolute;
			left:50%;
			width:50px;
			height:60px;
			top:-30px;
			margin-left:-25px;
			background-image:url('../img/static/aachercules-logo.png');
			background-size:contain;
			background-repeat:no-repeat;

			@include md {
				width:40px;
				height:50px;
				top:-25px;
				margin-left:-20px;
			}
		}

		// h2 {
		// 	font-size:40px;
		// 	line-height:40px;
		// }

		.content-inside {
			float:left;
			width:100%;
			margin-top:0px;

			@include md {
				margin-top:10px;
			}
		}

		.textblock-1 p {
			// font-size:14px;
			// line-height:24px;

			a {
				color:#000;
				text-decoration:none;
				opacity:0.5;
				transition:0.3s opacity;

				&:hover {
					opacity:1;
				}
			}
		}

	}

}
	