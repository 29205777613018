@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	h1 {
		font-size:40px;
		line-height:40px;
		padding:0;
		margin:0;
		margin-bottom:20px;
		font-weight:bold;

		@include md {
			font-size:20px;
			line-height:25px;
		}
	}

	h2 {
		font-size:40px;
		line-height:40px;
		padding:0;
		margin:0;
		margin-bottom:20px;
		font-weight:bold;

		@include md {
			font-size:20px;
			line-height:25px;
		}
	}

	// h3 {
	// 	font-size:1.2vw;
	// 	line-height:1vw;
	// 	padding:0;
	// 	margin:0;
	// 	margin-bottom:1vw;
	// 	font-weight:600;
	// 	text-transform:uppercase;
	// 	font-weight:normal;

	// 	@include md {
	// 		font-size:15px;
	// 		line-height:20px;
	// 	}
	// }

	p,
	.p {
		float:left;
		width:100%;
		font-size:28px;
		line-height:45px;
		margin:0;
		color:#000;

		@include md {
			font-size:16px;
			line-height:26px;
		}

		strong,
		&.strong {
			font-weight:600;
		}

		a {
			color:#000;
		}

	}

	ul li {
		font-size:28px;
		line-height:45px;

		@include md {
			font-size:16px;
			line-height:26px;
		}
	}

}

.textblock-2 {

	float:left;
	width:100%;

	h2 {
		font-size:25px;
		line-height:25px;
		padding:0;
		margin:0;
		margin-bottom:20px;
		font-weight:bold;

		@include md {
			font-size:20px;
			line-height:25px;
		}
	}

	p,
	.p {
		float:left;
		width:100%;
		font-size:21px;
		line-height:38px;
		margin:0;
		color:#000;

		@include md {
			font-size:16px;
			line-height:26px;
		}

		strong,
		&.strong {
			font-weight:600;
		}

		a {
			color:#000;
		}

	}

	ul li {
		font-size:21px;
		line-height:38px;

		@include md {
			font-size:16px;
			line-height:26px;
		}
	}

}