@import "_default.scss_include-mixins";

footer,
.footer {

	float:left;
	width:100%;

	.inside {
		float:left;
		width:100%;
		margin-top:40px;
		background-color:#2A7CB2;
		padding:20px;
		padding-top:25px;
		padding-left:40px;
		padding-right:40px;
		border-top-left-radius:50px;
		border-top-right-radius:50px;

		@include md {
			padding-left:20px;
			padding-right:20px;
			text-align:center;
			border-top-left-radius:30px;
			border-top-right-radius:30px;
		}

		.left {
			float:left;
			@include md {
				float:left;
				width:100%;
				margin-left:0;
				text-align:center;
				margin-bottom:20px;
			}
		}

		.middle {
			float:left;
			margin-left:80px;

			@include md {
				float:left;
				width:100%;
				margin-left:0;
				text-align:center;
				margin-bottom:20px;
			}

			a {
				float:left;
				margin-right:30px;

				@include md {
					display:inline-block;
					float:none;
					margin-bottom:5px;
					margin-right:0;
				}
			}
		}

		.right {
			float:right;

			@include md {
				float:left;
				width:100%;
				margin-left:0;
				text-align:center;
			}
		}

		p {
			margin:0;
			padding:0;
			color:#fff;
		}

		a {
			color:#fff;
			opacity:0.6;
			text-decoration:none;

			&:hover {
				opacity:1;
			}
		}
	}

}