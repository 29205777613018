@import "_default.scss_include-mixins";

.page.content--page {

	/*
	Bulbs
	*/
	.block.therapieen {
		.blur-bulb-1 {
			position:absolute;
			width:800px;
			height:800px;
			left:-200px;
			top:0px;
			opacity:0.3;
		}
	}

	.block.quote {
		.blur-bulb-1 {
			position:absolute;
			width:200px;
			height:200px;
			right:-100px;
			top:100px;
			opacity:0.5;
		}
	}

	/*
	Content
	*/
	.page-content {
		position:relative;
		float:left;
		margin-top:60px;
		width:100%;

		@include md {
			margin-top:100px;
		}

		.blur-bulb-1 {
			position:absolute;
			width:300px;
			height:300px;
			right:300px;
			top:-100px;
			opacity:0.3;

			@include md {
				width:100px;
				height:100px;
			}
		}

		.blur-bulb-2 {
			position:absolute;
			width:400px;
			height:400px;
			left:-300px;
			top:100px;
			opacity:0.3;

			@include md {
				width:200px;
				height:200px;
			}
		}

		table {
			width:100%;
			border:0;
			margin:0;

			tr td {
				padding:10px;
				padding-left:20px;
				padding-right:20px;
				background-color:#e1e1e1;
			}
		}

		.circle-image {
			display:inline-block;
			width:80%;
			padding-top:80%;
			background-color:#eee;
			border-radius:50%;
			background-repeat:no-repeat;
			background-size:cover;
			background-position:center;

			@include md {
				margin-top:20px;
			}
		}

		figure {
			display:inline-block;
			margin:0;
			padding:0;

			.inner-image {
				display:inline-block;
				width:90%;
				background-color:#e1e1e1;
				border-radius:30px;
				background-repeat:no-repeat;
				background-size:cover;
				background-position:center;
			}

		}

		.googlemaps {
			float:left;
			width:100%;
			height:400px;
			margin-top:20px;
			border-radius:20px;
			overflow:hidden;

			iframe {
				width:100%;
			}

			#googlemaps-inside {
				float:left;
				width:100%;
				height:100%;
				// mix-blend-mode: darken;
			}
		}

		.form-action {
			
			.form .element-row textarea {
				height:120px;
			}

			.button {
				float:right;
				margin:0;
				margin-top:20px;
			}
		}



	}

}